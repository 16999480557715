.recipeTile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.recipeTile__image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.recipeTile__name {
  max-width: 200px;
  text-align: center;
  margin: 1px 0;
  background-color: white;
}
