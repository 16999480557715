.tooltip {
    position: relative;
  }
  
      .tooltip .tooltiptext {
        visibility: hidden;
        background-color: #e8ed9a;
        border-radius: 6px;
        font-size: 12px;
        padding: 2px 2px;
        position: absolute;
        z-index: 1;
        top: 100%;
        left:30%;
        color:black;
      }

      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1.0;
      }