.app {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px;
}

.app__searchForm {
  margin-top: 17px;
}

.app__input {
  padding: 6px 16px;
  width: 160px;
  font-size: 17px;
  border-radius: 15px;
  border: 1px solid black;
}

.app__submit {
  padding: 6px 16px;
  font-size: 17px;
  margin-left: 6px;
  background-color: green;
  border: 2px solid green;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.app__favorites {
  padding: 6px 16px;
  font-size: 17px;
  margin-left: 6px;
  background-color: green;
  border: 2px solid green;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.app__submit:hover, .app__favorites:hover {
  padding: 6px 16px;
  font-size: 17px;
  margin-left: 6px;
  background-color: white;
  border: 2px solid green;
  border-radius: 15px;
  color: green;
  cursor: pointer;
}

.app__healthLabels {
  padding: 7px 8px;
  margin-left: 6px;
  border-radius: 14px;
  font-size: 15px;
}

.app__recipes {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 220px 220px 220px 220px;
  align-items: baseline;
}
